<template lang="pug">
  div
    h4.list-title {{ 'ui.labels.employees' | translate }}
    template(v-if="loaded")
      template(v-if="!isListEmpty")
        employee-item(
          v-for="(item, index) in items"
          :key="index"
          :item="item.employee"
          :has-role="item.hasRole"
          @add="onAdd(item)"
          @remove="onRemove(item)"
          @change="(value) => { item.hasRole = value }")
      v-list-empty(v-else-if="search.length")
        i.icon.icon--sm.icon--no-result
        span {{ 'ui.labels.no_results' | translate }}
      v-list-empty(v-else)
        i.icon.icon--sm.icon--empty
        span {{ 'ui.labels.no_added_items' | translate }}
    template(v-else)
      employee-item-skeleton(:count="4")

</template>

<script>
import EmployeeItem from './EmployeeItem'
import EmployeeItemSkeleton from '@/components/common/lists/EmployeeItemSkeleton'

export default {
  name: 'EmployeesList',

  components: {
    EmployeeItem,
    EmployeeItemSkeleton
  },

  props: {
    search: String,
    loaded: Boolean,
    items: Array
  },

  methods: {
    onAdd (item) {
      this.$emit('add', item)
    },

    onRemove (item) {
      this.$emit('remove', item)
    }
  },

  computed: {
    isListEmpty () {
      return this.items.length === 0
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
