<template lang="pug">
  v-list-item
    v-avatar.mr-3(
      :size="40"
      :src="item.profilePictureUrl")
    v-list-item-content
      v-list-item-title {{ item.firstName + ' ' + item.lastName }}
      v-list-item-subtitle {{ item.position ? item.position.name : '' }}
    v-list-item-actions
      v-btn(
        icon
        v-tooltip="$t('infotext.open_in_new_tab')"
        @click="openUserInNewWindowTab")
        i.fas.fa-external-link-alt

      v-btn(
        v-if="hasRole"
        icon
        danger
        :loading="loading"
        v-tooltip="$t('infotext.remove_user_from_role')"
        @click="toggleRoleLinking")
        i.fa.fa-trash-alt
      v-btn(
        v-else
        icon
        success
        :loading="loading"
        v-tooltip="$t('infotext.add_user_to_role')"
        @click="toggleRoleLinking")
        i.fa.fa-plus

</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'EmployeeItem',

  props: {
    item: Object,
    hasRole: Boolean
  },

  data: () => ({
    loading: false
  }),

  methods: {
    ...mapActions('employees', [
      'linkRole',
      'unlinkRole'
    ]),

    async toggleRoleLinking () {
      this.loading = true
      const params = {
        id: this.item.id,
        roleId: this.$route.params.role_id
      }
      if (this.hasRole) {
        let { error } = await this.unlinkRole(params)
        if (!error) {
          this.$emit('remove')
          this.$emit('change', false)
        }
      } else {
        let { error } = await this.linkRole(params)
        if (!error) {
          this.$emit('add')
          this.$emit('change', true)
        }
      }
      this.loading = false
    },

    openUserInNewWindowTab () {
      const routeData = this.$router.resolve({ name: `/employee/${this.item.id}` })
      window.open(routeData.route.name, '_blank')
    }
  }
}
</script>

<style lang="scss">
</style>
